@tailwind base;
@tailwind components;
@tailwind utilities;
@import "prism.css";

.amatic-sc-regular {
    font-family: "Amatic SC", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.amatic-sc-bold {
    font-family: "Amatic SC", sans-serif;
    font-weight: 700;
    font-style: normal;
}
